/**
 * Specific to sku catalog apis for now.
 * @param {object} resp Fetch response
 * @returns {object} { error: true/false, ...resp.json() }
 * 	if the json is not an object, it is put under the data property
 */
export const handleApiResponse = async (resp) => {
  let respJson
  try {
    respJson = await resp.json()
    if (!resp.ok) {
      if (typeof respJson !== "object") {
        respJson = { data: respJson }
      }
      return { error: true, ...respJson }
    } else {
      return { error: false, ...respJson }
    }
  } catch (e) {
    console.error(e)
    return { error: true, message: resp.statusText }
  }
}
