import { constants } from "@zippin-js/core"
import type { constants as constantTypes } from "@zippin-js/core"

const { CloudEnvironments } = constants.cloudEnvironments

interface WindowProps extends Window {
  env: {
    [key: string]: string | undefined
  }
}

const windowEnv = ((window as unknown) as WindowProps)?.env
const processEnv = process?.env
let env = processEnv?.REACT_APP_API_ENV === "development" ? processEnv : windowEnv

// Enum to defines the possible cloud the app is running on
const CLOUD_PROVIDERS: Record<string, string> = {
  GCP: "GCP",
  AZURE: "AZURE",
}

// Get the cloud provider the app is running on
const getCloudProvider = () =>
  Object.keys(CLOUD_PROVIDERS).find(
    (key) => CLOUD_PROVIDERS[key] === env?.REACT_APP_CLOUD?.toUpperCase()
  )

let memoizedEnvironment: constantTypes.cloudEnvironments.CloudEnvironments

// Get the environment the app is running on
const getEnvironment = (): constantTypes.cloudEnvironments.CloudEnvironments => {
  if (memoizedEnvironment) {
    return memoizedEnvironment
  }
  let environment = undefined

  if (env?.REACT_APP_ENV === "local") {
    environment = CloudEnvironments.LOCAL
  } else if (env?.REACT_APP_CLOUD === "gcp") {
    if (env?.REACT_APP_ENV === "testing") {
      environment = CloudEnvironments.GKED
    } else if (env?.REACT_APP_ENV === "production") {
      environment = CloudEnvironments.GKEP
    } else if (env?.REACT_APP_ENV === "qa") {
      environment = CloudEnvironments.GKEQ
    }
  } else if (env?.REACT_APP_CLOUD === "azure") {
    if (env?.REACT_APP_ENV === "testing") {
      environment = CloudEnvironments.AKSD
    } else if (env?.REACT_APP_ENV === "production") {
      environment = CloudEnvironments.AKSP
    }
  }

  if (!environment) {
    throw new Error(
      `Error: Couldn't determine the environment: (${env?.REACT_APP_ENV}, ${env?.REACT_APP_CLOUD})`
    )
  }

  memoizedEnvironment = environment

  return environment
}

const isLocalEnv = () => getEnvironment() === CloudEnvironments.LOCAL

const getAuth0Audience = () => env?.REACT_APP_AUTH0_AUDIENCE || "https://zippin-dashboard-dev/v1/"

const getAuth0ClientId = () => env?.REACT_APP_AUTH0_CLIENT_ID || "ZUVxCG9100x15pi2INIXsI0rkCBwSYOw"

const getDashboardServerAPI = () => env?.REACT_APP_ZIPPIN_DASHBOARD_SERVER_API

const getZippinPlatformAPI = () => env?.REACT_APP_ZIPPIN_PLATFORM_API

const getOperatorBackendAPI = () => env?.REACT_APP_OPERATOR_BACKEND_API

const getOperatorUIURL = () => env?.REACT_APP_OPERATOR_UI_URL

const getZAPI = () => env?.REACT_APP_Z_API

const getStoreConfigAPI = () =>
  env?.REACT_APP_STORE_CONFIG_API || "https://api-dev.zippin.ai/v1/store"

const getTaskAssignerAPI = () => env?.REACT_APP_TASK_ASSIGNER_API

const getOperatorBackendSocket = () => env?.REACT_APP_OPERATOR_BACKEND_SOCKET

const isMaintenanceMode = () => env?.REACT_APP_IS_MAINTENANCE_MODE === "true"

const getTaskAssignmentRuleAPI = () => env?.REACT_APP_TASK_ASSIGNMENT_RULE_API

const getTaskResultIngestorAPI = () => env?.REACT_APP_TASK_RESULT_INGESTOR_API

const getReactAppTurnServerKey = () => env?.REACT_APP_TURN_SERVER_KEY

const getReactAppTurnServerHostnames = () => env?.REACT_APP_TURN_SERVER_HOSTNAMES

const getReactAppIceServerURL = () => env?.REACT_APP_ICE_SERVER_URL

const getReactAppIceServerCredential = () => env?.REACT_APP_ICE_SERVER_CREDENTIAL

const getReactAppIceServerUsername = () => env?.REACT_APP_ICE_SERVER_USERNAME

/**
 * Get the base URL for store config.
 */
const getStoreConfigAPIBaseUrl = () => env?.REACT_APP_STORE_CONFIG_API

const getSupportDeskLink = () => "https://support.getzippin.com/hc/en-us"

/**
 * Get the base URL for app backend API.
 */
const getAppBackendBaseUrl = () => env?.REACT_APP_APP_BACKEND_API

export {
  getEnvironment,
  isLocalEnv,
  getCloudProvider,
  getAuth0Audience,
  getAuth0ClientId,
  getDashboardServerAPI,
  getZippinPlatformAPI,
  getOperatorBackendAPI,
  getOperatorUIURL,
  getZAPI,
  getStoreConfigAPI,
  getOperatorBackendSocket,
  isMaintenanceMode,
  getStoreConfigAPIBaseUrl,
  getAppBackendBaseUrl,
  getTaskAssignerAPI,
  getTaskAssignmentRuleAPI,
  getTaskResultIngestorAPI,
  getReactAppIceServerCredential,
  getReactAppIceServerURL,
  getReactAppTurnServerHostnames,
  getReactAppTurnServerKey,
  getReactAppIceServerUsername,
  getSupportDeskLink,
}
