// Ignore missing dep check here for explicit history dep.
// This implicitly uses react-router-dom history dep version.
import { createBrowserHistory } from "history"

let history = createBrowserHistory()

// Get the current location.
const location = history.location
console.log("browser history initial location: ", location)

history.listen((location) => {
  console.log("browser history location change: ", location)

  // Reload the page if user clicks the same page link by selecting different store from drawer menu
  const storeId = location?.state?.storeId
  const selectedStoreId = location?.state?.selectedStoreId
  if (storeId && selectedStoreId && storeId !== selectedStoreId) {
    window.location.reload()
  }
})

export default history
