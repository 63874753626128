import React, { Component } from "react"

import TitledRoute from "./TitledRoute"
import { withAuthenticationRequired } from "@auth0/auth0-react"

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

/**
 * A wrapped TitledRoute, which is only accessible,
 * if the user has been authenticated (logged in). If the user has
 * not logged in, the user will be redirected to login. Passes props
 * to the component rendered when the route is accessed.
 */
class PrivateRoute extends Component {
  render() {
    const { component, ...rest } = this.props
    return (
      <TitledRoute
        {...rest}
        render={(routeProps) => {
          return renderMergedProps(this.props.component, routeProps, rest)
        }}
      />
    )
  }
}

/**
 * Auth0Provider will redirect back to this private route if the user hasn't
 * been authenticated yet.
 */
export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
})
