import React, { Component } from "react"
import { withAuth0 } from "@auth0/auth0-react"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import MenuIcon from "@material-ui/icons/Menu"
import DashboardIcon from "@material-ui/icons/Dashboard"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Support from "@material-ui/icons/Help"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import HomeIcon from "@material-ui/icons/Home"
import StoreIcon from "@material-ui/icons/Store"
import classNames from "classnames"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormControl from "@material-ui/core/FormControl"
import { Link, withRouter } from "react-router-dom"
import queryString from "query-string"
import { connect } from "react-redux"
import ZIPPINLogo from "../static/ZIPPIN.png"
import Select from "@atlaskit/select"
import Chip from "@material-ui/core/Chip"
import AccountCircle from "@material-ui/icons/AccountCircle"
import * as MenuIcons from "./presentational/MenuIcons"
import * as api from "../utils/api"
import * as storesApi from "./store-config/components/stores/storesApi"
import * as env from "../utils/env"
import { StoreType } from "store-config-interfaces"
import AssessmentIcon from "@material-ui/icons/Assessment"
import { getBearerToken } from "../api/apiHelpers"
import { Grid } from "@material-ui/core"
import GroupAddIcon from "@material-ui/icons/GroupAdd"
import SettingsIcon from "@mui/icons-material/Settings"
import { getCurrentStoreUserRole } from "../utils/user"
import { currentThemeId } from "../App"
import { StoreModeBadge } from "@zippin-js/component-library"

const TIME_TO_RE_RENDER_SEC = 10
const MS_IN_A_SEC = 1000

const styles = (theme) => ({
  root: {
    height: "inherit",
    boxShadow: theme.shadows.unit * 0,
  },
  appBar: {
    maxHeight: "64px",
    overflowX: "hidden",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  appBarItem: {
    marginLeft: "10px",
  },
  pageTitle: {
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  pageTitleText: {
    paddingTop: "3px",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "90%",
    minWidth: 120,
    paddingTop: "10px",
  },
  content: {
    height: "inherit",
  },
})

function ListItemLink(props) {
  function onClickStore(e) {
    onClick()
    if (!props.externallink) return
    e.preventDefault()
    window.open(props.href, "_blank")
  }

  let { externallink, onClick, ...restProps } = props

  return (
    <ListItem
      button
      component={Link}
      to={{
        pathname: props.href,
        state: { storeId: props.storeid, selectedStoreId: props.selectedstoreid },
      }}
      onClick={onClickStore}
      {...restProps}
    />
  )
}

class MainAppBar extends Component {
  state = {
    isDrawerOpen: false,
    selectedStore: {},
    labelWidth: 0,
    stores: [],
    error: null,
    status: null,
    userRole: "",
    authenticated: null,
    storeId: this.props.match.params.id,
    bearerToken: null,
    refreshKey: 0,
  }

  stateHistory = {} // object to store state history

  constructor(props) {
    super(props)
    this.getStores = this.getStores.bind(this)
    this.logout = this.logout.bind(this)
    this.handleDefaultStoreChange = this.handleDefaultStoreChange.bind(this)
    this.lookupStoreInfo = this.lookupStoreInfo.bind(this)
    this.setSelectedStore = this.setSelectedStore.bind(this)
    this.setUserStoreAccess = this.setUserStoreAccess.bind(this)
  }

  componentDidMount() {
    const { isAuthenticated } = this.props.auth0

    // Set initial state
    this.setState({ authenticated: isAuthenticated, loading: true })

    // If not authenticated skip the API calls
    if (!isAuthenticated) return

    // API call logic
    this.getStores()
    getBearerToken().then((token) => {
      this.setState({
        bearerToken: token,
      })
    })

    this.interval = setInterval(() => {
      getBearerToken().then((token) => {
        this.setState({
          bearerToken: token,
        })
      })
      this.setState((prevState) => ({ refreshKey: prevState.refreshKey + 1 }))
    }, TIME_TO_RE_RENDER_SEC * MS_IN_A_SEC)
  }

  componentDidUpdate(prevProps) {
    const { location, auth0 } = this.props
    if (location !== prevProps.location) {
      if (this.state.stores.length === 0) {
        this.getStores()
      } else {
        this.setSelectedStore()
      }
    }
    if (auth0?.isAuthenticated !== prevProps?.auth0.isAuthenticated) {
      this.setState({ authenticated: this.props.auth0.isAuthenticated })
      this.getStores()
    }
    // Check if userStoreAccess has changed before calling setUserStoreAccess
    if (this.props.userStoreAccess !== prevProps.userStoreAccess) {
      this.setUserStoreAccess()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getStores() {
    if (this.props.auth0?.isAuthenticated) {
      storesApi
        .getStores()
        .then((stores) => {
          this.setState({ status: "success", stores, loading: false }, () => {
            this.setSelectedStore()
          })
        })
        .catch((error) => {
          console.log("error:" + error)
          this.setState({ status: "error", error, loading: false }, () => {
            this.setSelectedStore()
          })
        })
    }
  }

  logout() {
    this.props.auth0.logout({ returnTo: window.location.origin })
  }

  toggleDrawer = (openDrawer) => () => {
    this.setState({
      isDrawerOpen: openDrawer,
    })
  }

  handleDefaultStoreChange = (selectedStore) => {
    // console.log("handleDefaultStoreChange selectedStore", selectedStore)
    this.setState({ selectedStore }, () => {
      this.setUserStoreAccess()
    })
  }

  setUserStoreAccess() {
    this.setState({
      userRole: getCurrentStoreUserRole(this.props.userStoreAccess, this.state.storeId),
    })
  }

  lookupStoreInfo(storeIdText) {
    if (this.state.stores) {
      let selectedStore
      this.state.stores.some((store) => {
        if (storeIdText && storeIdText.includes(store.id)) {
          // console.log('setSelectedStore', store)
          selectedStore = { label: store.name, value: store.id, type: store.type }
          // console.log('store match from url location', store)
          return true
        } else {
          return false
        }
      })
      return selectedStore
    }
  }

  setSelectedStore(selectedStore) {
    // handle urls where store id is in the path
    if (!selectedStore) {
      const { location } = this.props
      // console.log('setSelectedStore', this.props, this.state)
      selectedStore = this.lookupStoreInfo(location.pathname)
    }
    // handle urls where store id is a parameter ?storeId=
    if (!selectedStore && window.location.search) {
      const parsed = queryString.parse(window.location.search)
      selectedStore = this.lookupStoreInfo(parsed["storeId"])
    }
    // just pick the first store in the list, if nothing works
    if (!selectedStore && this.state.stores && this.state.stores.length > 0) {
      const store = this.state.stores[0]
      selectedStore = { label: store.name, value: store.id, type: store.type }
    }
    // console.log('setselectedstore', selectedStore)
    if (selectedStore) {
      this.setState({ selectedStore, storeId: selectedStore.value }, () => {
        this.setUserStoreAccess()
      })
    }
  }

  render() {
    const isUserAdmin = this.state.userRole === "admin"
    const isUserRetailer = this.state.userRole === "retailer"
    const storesOptions = this.state.stores
      ? this.state.stores.map((store) => {
          return { label: store.name, value: store.id, type: store.type }
        })
      : []
    const { classes } = this.props
    const { isDrawerOpen } = this.state
    if (!this.state.isDrawerOpen && this.state.storeId && this.state.userRole) {
      this.stateHistory = this.state
    }

    const themeId = currentThemeId

    const getAppBarTitle = () => {
      if (isUserRetailer) {
        if (this.state.isDrawerOpen) {
          return this.stateHistory.selectedStore.label
        } else {
          return this.state.selectedStore.label
        }
      } else {
        if (this.state.isDrawerOpen) {
          return `${this.stateHistory.selectedStore.label}(${this.stateHistory.selectedStore.value})`
        } else {
          return `${this.state.selectedStore.label}(${this.state.selectedStore.value})`
        }
      }
    }
    return (
      <div className={classes.root}>
        <CssBaseline />
        {this.state.authenticated && (
          <AppBar className={[classes.appBar, "app-bar"]} position="fixed" color="inherit">
            <Toolbar disableGutters>
              <IconButton
                color="inherit"
                disabled={env.isMaintenanceMode()}
                aria-label="Open drawer"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>

              <Link to="/home" className={classes.appBarItem}>
                <img src={ZIPPINLogo} alt="Zippin Logo" />
              </Link>

              <Grid container className={[classes.appBarItem, classes.pageTitle]} spacing={2}>
                <Typography variant="h6" color="inherit" className={classes.pageTitleText}>
                  {this.props.title} - {getAppBarTitle()}
                </Typography>
                <StoreModeBadge
                  key={this.state.refreshKey}
                  themeId={themeId}
                  bearerToken={this.state.bearerToken}
                  cloudEnvOrBaseUrl={env.getEnvironment()}
                  storeId={this.state.selectedStore.value}
                  overrideUserRole={this.state.userRole}
                />
              </Grid>

              <a
                href={env.getSupportDeskLink()}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames(classes.appBarItem)}
              >
                Support Request
              </a>

              <a
                href={`https://support.getzippin.com/hc/en-us/categories/4402643549716-RETAILER-OPERATIONS-MANUAL`}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames(classes.appBarItem)}
              >
                Retailer Manual
              </a>

              <Chip
                className={classes.appBarItem}
                icon={<AccountCircle />}
                variant="outlined"
                label={this.props.auth0.user.email}
                style={{ border: 0 }}
              />
              <Button color="inherit" onClick={this.logout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <Drawer open={isDrawerOpen} onClose={this.toggleDrawer(false)}>
          <div tabIndex={0} role="button">
            <FormControl className={classes.formControl}>
              <Select
                className="single-select"
                classNamePrefix="react-select"
                value={this.state.selectedStore}
                onChange={this.handleDefaultStoreChange}
                options={storesOptions}
              />
            </FormControl>
            <div className={classes.list}>
              <List>
                <ListItemLink href="/home" onClick={this.toggleDrawer(false)}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItemLink>
                <ListItemLink
                  href={`/stores/${this.state.selectedStore.value}`}
                  onClick={this.toggleDrawer(false)}
                  storeid={this.state.storeId}
                  selectedstoreid={this.state.selectedStore.value}
                >
                  <ListItemIcon>
                    <StoreIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Store"} />
                </ListItemLink>
                {isUserAdmin && (
                  <React.Fragment>
                    <ListItemLink
                      href={`${api.OPERATOR_UI_URL}/tasks`}
                      externallink={true}
                      onClick={this.toggleDrawer(false)}
                    >
                      <ListItemIcon>{<MenuIcons.OperatorTasksIcon />}</ListItemIcon>
                      <ListItemText primary={"Operator Tasks"} />
                    </ListItemLink>
                    <ListItemLink
                      href={`${api.OPERATOR_UI_URL}/verify/${this.state.selectedStore.value}`}
                      externallink={true}
                      onClick={this.toggleDrawer(false)}
                    >
                      <ListItemIcon>{<MenuIcons.OperatorVerifyIcon />}</ListItemIcon>
                      <ListItemText primary={"Operator Verify"} />
                    </ListItemLink>
                  </React.Fragment>
                )}
                {isUserAdmin && (
                  <ListItemLink href={`/operator_dashboard`} onClick={this.toggleDrawer(false)}>
                    <ListItemIcon>{<MenuIcons.OperatorDashBoardIcon />}</ListItemIcon>
                    <ListItemText primary={"Operator Dashboard"} />
                  </ListItemLink>
                )}
                {isUserAdmin && (
                  <ListItemLink
                    href={`/carts_dashboard/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<MenuIcons.CartsDashIcon />}</ListItemIcon>
                    <ListItemText primary={"Carts Dashboard"} />
                  </ListItemLink>
                )}
                <ListItemLink
                  href={`/shelf_inventory/${this.state.selectedStore.value}`}
                  onClick={this.toggleDrawer(false)}
                  storeid={this.state.storeId}
                  selectedstoreid={this.state.selectedStore.value}
                >
                  <ListItemIcon>{<MenuIcons.ShelfInventoryIcon />}</ListItemIcon>
                  <ListItemText primary={"Shelf Inventory"} />
                </ListItemLink>
                {isUserAdmin && (
                  <ListItemLink
                    href={`/shelves_config/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                  >
                    <ListItemIcon>{<MenuIcons.ShelvesConfigIcon />}</ListItemIcon>
                    <ListItemText primary={"Shelves Config"} />
                  </ListItemLink>
                )}
                <ListItemLink
                  href={`/skus/${this.state.selectedStore.value}`}
                  onClick={this.toggleDrawer(false)}
                  storeid={this.state.storeId}
                  selectedstoreid={this.state.selectedStore.value}
                >
                  <ListItemIcon>{<MenuIcons.SkuCatalogIcon />}</ListItemIcon>
                  <ListItemText primary={"SKU Catalog"} />
                </ListItemLink>
                <ListItemLink
                  href={`/lane_calibration/${this.state.selectedStore.value}`}
                  onClick={this.toggleDrawer(false)}
                  storeid={this.state.storeId}
                  selectedstoreid={this.state.selectedStore.value}
                >
                  <ListItemIcon>{<MenuIcons.LaneCalibrationIcon />}</ListItemIcon>
                  <ListItemText primary={"Lane Calibration"} />
                </ListItemLink>

                {/* This menu item should only be shown for vending machines,
                 but the store type is not exposed here. */}
                {this.state.selectedStore?.type === StoreType.VM && (
                  <ListItemLink
                    href={`/data_collection/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<MenuIcons.DataCollectionIcon />}</ListItemIcon>
                    <ListItemText primary={"Data Collection"} />
                  </ListItemLink>
                )}
                {isUserAdmin && (
                  <React.Fragment>
                    <ListItemLink
                      href={`/events_dashboard/${this.state.selectedStore.value}`}
                      onClick={this.toggleDrawer(false)}
                      storeid={this.state.storeId}
                      selectedstoreid={this.state.selectedStore.value}
                    >
                      <ListItemIcon>{<MenuIcons.EventsDashIcon />}</ListItemIcon>
                      <ListItemText primary={"Events Dashboard"} />
                    </ListItemLink>
                    <ListItemLink
                      href={`/refunds/${this.state.selectedStore.value}`}
                      onClick={this.toggleDrawer(false)}
                      storeid={this.state.storeId}
                      selectedstoreid={this.state.selectedStore.value}
                    >
                      <ListItemIcon>{<MenuIcons.CartRefundIcon />}</ListItemIcon>
                      <ListItemText primary={"Refund Dashboard"} />
                    </ListItemLink>

                    <ListItemLink
                      href={`/device_control/${this.state.selectedStore.value}`}
                      onClick={this.toggleDrawer(false)}
                      storeid={this.state.storeId}
                      selectedstoreid={this.state.selectedStore.value}
                    >
                      <ListItemIcon>{<MenuIcons.DeviceControlIcon />}</ListItemIcon>
                      <ListItemText primary={"Device Control"} />
                    </ListItemLink>
                  </React.Fragment>
                )}
                <React.Fragment>
                  <ListItemLink
                    href={`/orders/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<MenuIcons.OrdersDashIcon />}</ListItemIcon>
                    <ListItemText primary={"Orders Dashboard"} />
                  </ListItemLink>
                </React.Fragment>

                <React.Fragment>
                  <ListItemLink
                    href={`/new-reports/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<DashboardIcon />}</ListItemIcon>
                    <ListItemText primary={"Reports Dashboard"} />
                  </ListItemLink>
                </React.Fragment>

                {isUserAdmin && (
                  <React.Fragment>
                    <ListItemLink
                      href={`/hitl_dashboard/active-users`}
                      onClick={this.toggleDrawer(false)}
                    >
                      <ListItemIcon>{<AssessmentIcon />}</ListItemIcon>
                      <ListItemText primary={"HITL Dashboard"} />
                    </ListItemLink>
                  </React.Fragment>
                )}
                {isUserAdmin && (
                  <React.Fragment>
                    <ListItemLink
                      href={`/support/${this.state.selectedStore.value}`}
                      onClick={this.toggleDrawer(false)}
                    >
                      <ListItemIcon>{<Support />}</ListItemIcon>
                      <ListItemText primary={"Crew Support"} />
                    </ListItemLink>
                  </React.Fragment>
                )}

                <React.Fragment>
                  <ListItemLink
                    href={`/access_management/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<GroupAddIcon />}</ListItemIcon>
                    <ListItemText primary={"Access Management"} />
                  </ListItemLink>
                </React.Fragment>

                <React.Fragment>
                  <ListItemLink
                    href={`/configurations/payment/${this.state.selectedStore.value}`}
                    onClick={this.toggleDrawer(false)}
                    storeid={this.state.storeId}
                    selectedstoreid={this.state.selectedStore.value}
                  >
                    <ListItemIcon>{<SettingsIcon />}</ListItemIcon>
                    <ListItemText primary={"Configurations"} />
                  </ListItemLink>
                </React.Fragment>
              </List>
            </div>
          </div>
        </Drawer>
        <main style={{ paddingTop: this.state.authenticated ? 64 : 0 }} className={classes.content}>
          {this.props.children}
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.title,
  userStoreAccess: state.user.userStoreAccess,
})

export default withAuth0(withRouter(withStyles(styles)(connect(mapStateToProps)(MainAppBar))))
