import {
  CrewAppCloudEnvironmentEnum,
  SkuStoreMapPostParams,
  SkuStoreMapPostResponse,
  Store,
  StoreConfigPutRequestBody,
  StoreConfigValuesType,
  StorePostParams,
  StorePutParams,
  TenantDBStorePostParams,
} from "store-config-interfaces"
import type { constants as constantTypes } from "@zippin-js/core"
import { utils } from "@zippin-js/core"

import * as env from "../../../../utils/env"
import { getUserEmail, wrappedFetch } from "../../../../utils/api"
import { getStoreConfigGCPBaseUrl } from "../../../../utils/common"

export async function getStores(): Promise<Store[]> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/stores`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  const responseJson = await response.json()
  let stores: Store[] = []

  if (Array.isArray(responseJson)) {
    // @hemantbhanoo: 2024-08-06 - the api is returning snake_case just for this one field.
    // transform it so we don't break elsewhere.
    responseJson.forEach((store) => (store.tStoreId = store.t_store_id))
    stores = (responseJson as unknown) as Store[]
  }

  if (stores.length > 0) {
    stores.sort((a, b) => a.name.localeCompare(b.name))
  }
  return stores
}

async function getStore(id: string): Promise<Response> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/stores/${id}`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  return response
}

export async function createStore(requestBody: StorePostParams): Promise<{ status: number }> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/stores`
  const response = await wrappedFetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
  })
  return { status: response.status }
}

export async function editStore(
  storeId: string,
  requestBody: StorePutParams
): Promise<{ status: number; store: Store }> {
  // The following line is needed because the server is returning true/false values instead of 1/0
  // If we don't transform it before sending it back, we end up getting a server-side validation error.
  if ("active" in requestBody && typeof requestBody.active === "boolean") {
    requestBody.active = requestBody.active ? 1 : 0
  }

  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/stores/${storeId}`
  const response = await wrappedFetch(url, {
    method: "PUT",
    body: JSON.stringify(requestBody),
  })
  const store = await response.json()
  return { status: response.status, store }
}

export const doesStoreExist = async (storeId: string): Promise<boolean> => {
  const getStoreResponse = await getStore(storeId)
  if (getStoreResponse.status === 404) {
    return false
  }
  return true
}

/**
 * The function makes API call and gets store's cloud features
 * @param {string} storeId The id of a store
 * @param {constantTypes.storeConfig.StoreConfigKey} configKey The config value to retrieve
 */
export async function getStoreConfig(
  storeId: string,
  configKey: constantTypes.storeConfig.StoreConfigKey
): Promise<StoreConfigValuesType | null> {
  const environment = env.getEnvironment()

  if (environment == null) {
    throw new Error(
      `Unrecognized Environment: A valid environment is required for storeConfig API calls`
    )
  }

  const gcpOverride = utils.storeConfig.shouldOverrideGcpForConfigKey(configKey)
  const storeConfigApiBaseUrl = utils.storeConfig.getBaseStoreConfigUrl(environment, gcpOverride)
  const url = `${storeConfigApiBaseUrl}/stores/${storeId}/config/${configKey}`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  // When not configured, the API returns no content
  if (response.status === 204) {
    return null
  }
  if (response.status !== 200) {
    throw new Error(`Fetch failed for ${storeId}, ${configKey}`)
  }
  return await response.json()
}

/**
 * The function makes API call and create store's cloud features.
 * @param {string} storeId the id of a store.
 * @param {constantTypes.storeConfig.StoreCloudServiceFeatures} requestBody the store feature values.
 */
export async function createStoreConfig(
  storeId: string,
  sKey: string,
  sValue: string
): Promise<{ status: number }> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const updatedBy = getUserEmail()
  const url = `${storeConfigApi}/store-config/stores/${storeId}/config/${sKey}`
  const response = await wrappedFetch(url, {
    method: "PUT",
    body: JSON.stringify({
      sValue,
      updatedBy,
    }),
  })
  return { status: response.status }
}

/**
 * API call to update the store's cloud features
 * @param {string} storeId the id of a store
 * @param {constantTypes.storeConfig.StoreConfigKey} configKey The config to update
 * @param {StoreConfigValuesType} newValue the new value to be set
 */
export async function updateStoreConfig(
  storeId: string,
  configKey: constantTypes.storeConfig.StoreConfigKey,
  newValue: StoreConfigValuesType
): Promise<{ status: number }> {
  const environment = env.getEnvironment()

  if (environment == null) {
    throw new Error(
      `Unrecognized Environment: A valid environment is required for storeConfig API calls`
    )
  }

  const updatedBy = getUserEmail()
  const gcpOverride = utils.storeConfig.shouldOverrideGcpForConfigKey(configKey)
  const storeConfigApiBaseUrl = utils.storeConfig.getBaseStoreConfigUrl(environment, gcpOverride)
  const url = `${storeConfigApiBaseUrl}/stores/${storeId}/config/${configKey}`
  const body: StoreConfigPutRequestBody = { updatedValue: newValue, updatedBy: updatedBy }
  const response = await wrappedFetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
  })
  return { status: response.status }
}

/**
 * An API call and create store's cloud features.
 * @param {string} storeId the id of a store.
 * @param {CrewAppCloudEnvironmentEnum} cloudEnv the cloud environment of store launched.
 */
export async function crewAppSetup(
  storeId: string,
  cloudEnv: CrewAppCloudEnvironmentEnum
): Promise<{ status: number }> {
  // This API is to be called on GCP for both azure and gcp.
  const storeConfigApi = getStoreConfigGCPBaseUrl()
  const updatedBy = getUserEmail()
  const url = `${storeConfigApi}/stores/${storeId}/crew-app-setup`
  const requestBody = {
    cloudEnv: cloudEnv,
    updatedBy: updatedBy,
  }
  const response = await wrappedFetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
  })
  return { status: response.status }
}

/**
 * An API to get suppourted currencies.
 */
export async function getCurrencies(): Promise<any> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/currencies`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  const result = await response.json()
  return result
}

/**
 * An API to get suppourted countries.
 */
export async function getCountries(): Promise<any> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/countries`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  const result = await response.json()
  return result
}

/**
 * An API call get tenant from tenant DB.
 * @param {string} tenantId the id of a tenant.
 */
export async function getTenant(tenantId: string): Promise<number> {
  // Tenant DB only exists on GCP, the API call is to be made on GCP for both azure and gcp.
  const storeConfigApi = getStoreConfigGCPBaseUrl()
  const url = `${storeConfigApi}/tenants/tenantDb/${tenantId}`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  return response.status
}

/**
 * An API call save store details for billing setup.
 * @param {TenantDBStorePostParams} billingDetails the store details for billing setup.
 */
export async function billingSetup(billingDetails: TenantDBStorePostParams): Promise<number> {
  // // Tenant DB only exists on GCP, the API call is to be made on GCP for both azure and gcp.
  const storeConfigApi = getStoreConfigGCPBaseUrl()
  const url = `${storeConfigApi}/stores/tenantDb/stores`
  const requestBody = { ...billingDetails }
  const response = await wrappedFetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
  })
  return response.status
}

/**
 * To fetch the store from tenant DB.
 * @param storeId - The id of a store.
 */
export async function getTenantDbStore(storeId: string): Promise<TenantDBStorePostParams | null> {
  // Tenant DB only exists on GCP, the API call is to be made on GCP for both azure and gcp.
  const storeConfigApi = getStoreConfigGCPBaseUrl()
  const url = `${storeConfigApi}/stores/tenantDb/${storeId}`
  const response = await wrappedFetch(url, {
    method: "GET",
  })
  if (response.status !== 200) {
    return null
  }
  return response.json()
}

export async function storeSkuMap(
  storeSkuPostParams: SkuStoreMapPostParams,
  storeId: string
): Promise<SkuStoreMapPostResponse> {
  const storeConfigApi = env.getStoreConfigAPIBaseUrl()
  const url = `${storeConfigApi}/stores/${storeId}/skuMapping`
  const requestBody = storeSkuPostParams
  const response = await wrappedFetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
  })
  if (response.status !== 200) {
    throw new Error(`Error SKU mapping for new store ${storeId}.`)
  }
  return await response.json()
}
