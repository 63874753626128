import * as storesApi from "../../components/store-config/components/stores/storesApi"
export const SAVE_IMPORT_SKU_RESULTS = "SAVE_IMPORT_SKU_RESULTS"
export const REMOVE_IMPORT_SKU_RESULTS = "REMOVE_IMPORT_SKU_RESULTS"

const shouldFetchUserStores = (state) => {
  // don't get order details if state already has them
  if (state.stores && state.stores.userStores && state.stores.userStores.length > 0) {
    return false
  }

  return true
}

const fetchUserStores = async () => {
  console.log("action fetchUserStores()")
  let stores = []
  try {
    let getStoresResult = await storesApi.getStores()
    if (getStoresResult) {
      stores = getStoresResult
    } else {
      console.log("No user stores fetched.", getStoresResult)
    }
  } catch (e) {
    console.log("Error fetching user stores", e)
  }
  return stores
}

/**
 * Fetches the stores the user has access to
 */
export const actionFetchUserStores = () => {
  console.log("action actionfetchUserStores()")
  return async (dispatch, getState) => {
    try {
      let state = getState()
      if (shouldFetchUserStores(state)) {
        let stores = await fetchUserStores()
        dispatch({ type: "SET_USER_STORES", stores })
        dispatch({ type: "HAS_FETCHED_USER_STORES", hasFetched: true })
      }
    } catch (e) {
      console.log("Error fetching user stores", e)
    }
  }
}

const shouldFetchTenantSkuCategories = (state, newTenantId) => {
  // don't get tenant sku categories if state already has them and if tenantId is the same
  if (
    state.skus &&
    state.skus.categories &&
    state.skus.categories.length > 0 &&
    state.skus.tenantId === newTenantId
  ) {
    return false
  }

  return true
}

const fetchTenantSkuCategories = async (api, tenantId) => {
  console.log("action fetchTenantSkuCategories()")
  let categories = []
  try {
    let apiResp = await api.querySkuCategories(tenantId)

    if (Array.isArray(apiResp)) {
      categories = apiResp
    } else {
      console.log("No tenant sku categories found.", apiResp)
    }
  } catch (e) {
    console.log("Error fetchTenantSkuCategories", e)
  }
  return categories
}

/**
 * Fetches a tenants sku categories
 */
export const actionFetchTenantSkuCategories = (tenantId, isCategory) => {
  console.log("action actionFetchTenantSkuCategories()")
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState()
      if (shouldFetchTenantSkuCategories(state, tenantId) || isCategory) {
        let categories = await fetchTenantSkuCategories(api, tenantId)
        dispatch({ type: "SET_TENANT_SKU_CATEGORIES", categories })
        dispatch({ type: "SET_SKU_CATEGORIES_TENANT_ID", tenantId })
        dispatch({ type: "HAS_FETCHED_TENANT_SKU_CATEGORIES", hasFetched: true })
      }
    } catch (e) {
      console.log("Error actionFetchTenantSkuCategories", e)
    }
  }
}
