import React, { Suspense, lazy, useState, useEffect, useRef } from "react"
import "./App.css"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { withAuth0, useAuth0 } from "@auth0/auth0-react"

import { Themes } from "@zippin-js/component-library"

import PrivateRoute from "./components/PrivateRoute"
import history from "./history"
import TitledRoute from "./components/TitledRoute"
import MainAppBar from "./components/MainAppBar"

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { red, blue } from "@material-ui/core/colors"
import { StylesProvider } from "@material-ui/styles"
import openSocket from "socket.io-client"
import * as env from "./utils/env"
import ZFullScreenLoading from "./components/presentational/ZFullScreenLoading"

import { actionFetchUserStoreAccess } from "./store/actions/user"

// begin store/home pages
const Store = lazy(() => import("./components/Store/Store"))
const Stores = lazy(() => import("./components/Stores"))
const Logout = lazy(() => import("./components/Logout"))
const Home = lazy(() => import("./components/Auth/Home"))
// end store pages

// begin order pages
const CartsDashboard = lazy(() => import("./components/carts/Carts"))
const Orders = lazy(() => import("./components/Orders"))
const RefundDashboard = lazy(() => import("./components/refunds/Refunds"))
const CartDetail = lazy(() => import("./components/orders/CartDetail"))
const ReportDashboardV2 = lazy(() => import("./components/ReportDashboardv2/ReportDashboard"))
const Reports = lazy(() => import("./components/reports/Reports"))
// end order pages

// begin hardware pages
const ShelfInventory = lazy(() => import("./components/ShelfInventory/ShelfInventory"))
const LaneCalibration = lazy(() => import("./components/Lanes/LaneCalibration"))
const Annotator = lazy(() => import("./components/Annotator/Annotator"))
const StoreConfigApp = lazy(() => import("./components/store-config/StoreConfigApp"))
const CamerasGraphManagement = lazy(() =>
  import("./components/Store/store_tasks/camera_calibration/pair_management/CamerasGraphManagement")
)
const ShelvesConfig = lazy(() => import("./components/shelves_config/ShelvesConfig"))
const DeviceControlPage = lazy(() => import("./components/device_control/DeviceControlsPage"))
const StoreMultipleCameraView = lazy(() => import("./components/StoreMultipleCameraView"))
const RelativeVideoViewDashboard = lazy(() => import("./components/RelativeVideoViewDashboard"))
// end order pages

// begin HITL pages
const EventsDashboard = lazy(() => import("./components/EventsDashboard/Events"))
const OperatorDashboard = lazy(() => import("./components/OperatorDashboard"))
const ActiveUsers = lazy(() => import("./components/HITLDashboard/ActiveUsers/ActiveUsers"))
const PausedStores = lazy(() => import("./components/HITLDashboard/PausedStores/PausedStores"))
const PendingCarts = lazy(() => import("./components/HITLDashboard/PendingCarts/PendingCarts"))
const AssignmentRules = lazy(() =>
  import("./components/HITLDashboard/AssignmentRules/AssignmentRules")
)
const AutoSolveHITLTasksRules = lazy(() =>
  import("./components/HITLDashboard/AutoSolveTasksRules/AutoSolveTasksRules")
)
const ExpertOperators = lazy(() =>
  import("./components/HITLDashboard/ExpertOperators/ExpertOperators")
)
const ErrorQueue = lazy(() => import("./components/HITLDashboard/ErrorQueue/ErrorQueue"))
const StorePriority = lazy(() => import("./components/HITLDashboard/StorePriority/StorePriority"))
const PendingTasks = lazy(() => import("./components/HITLDashboard/PendingTasks"))
const AdminPanel = lazy(() => import("./components/HITLDashboard/AdminPanel/AdminPanel"))
// end HITL pages

// begin Store Management pages
const UserManagement = lazy(() => import("./components/UserManagement/UserManagement"))
const ConfigurationsPage = lazy(() => import("./components/Configurations/ConfigurationsPage"))
const IssueForm = lazy(() => import("./components/Configurations/IssueForm"))
const Skus = lazy(() => import("./components/Sku/Skus"))
const Support = lazy(() => import("./components/Support/support"))
const Customers = lazy(() => import("./components/Customers"))
// end Store Management pages

// Establish the ThemeId context and default value
const currentThemeId = Themes.LIGHT
const ThemeIdContext = React.createContext(currentThemeId)

let socketUrl = env.getOperatorBackendSocket()
if (!socketUrl) {
  // defaults to gcp test environment if api enpoint env var is not set
  console.error("Socket URL is not set.")
}

let environment, cloudProvider

try {
  environment = env.getEnvironment()
  cloudProvider = env.getCloudProvider()
} catch (error) {
  console.error("Failed to get environment:", error)
}

console.log(
  `CLOUD: ${cloudProvider}\
  ENV: ${environment}\
  App: (version: ${process.env.REACT_APP_VERSION})`
)

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: "#66bb6a",
    },
    error: red,
    zippin: "#FF5C39",
    zippinDark: "#d64f39",
    background: {
      default: "#FFFFFF",
    },
  },
})

if (env.isMaintenanceMode()) {
  console.log("Zippin dashboards in maintanence mode")
  history.push("/Home")
}

export const STORE_CONFIG_BASE_PATH = "/store_config"

function App() {
  const auth0Provider = useAuth0()
  const [isSocketIOInit, setIsSocketIOInit] = useState(false)
  const title = useSelector((state) => state.title)
  const userStoreAccessList = useSelector((state) => state.user.userStoreAccess)
  const dispatch = useDispatch()
  let socket = useRef(null)

  useEffect(() => {
    const initializeSocketConnection = async () => {
      try {
        const accessToken = await auth0Provider.getAccessTokenSilently({
          audience: env.getAuth0Audience(),
        })

        socket.current = openSocket(socketUrl, {
          forceNew: true,
          pingTimeout: 30000,
          withCredentials: true,
          auth: {
            authorization: `Bearer ${accessToken}`,
          },
          transports: ["websocket", "polling"],
        })

        socket.current.on("connect", () => {
          socket.current.on("unauthorized", (msg) => {
            console.log("unauthorized = ", msg)
          })
        })
        setIsSocketIOInit(true)
      } catch (e) {
        console.error("socket auth error: ", e)
      }
    }
    if (auth0Provider.isAuthenticated) {
      initializeSocketConnection()
      // checkUserAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Provider.isAuthenticated])

  useEffect(() => {
    dispatch({ type: "CHANGE_TITLE", title: title })
  }, [title, dispatch])

  useEffect(() => {
    if (userStoreAccessList == null) {
      dispatch(actionFetchUserStoreAccess())
    }
  }, [userStoreAccessList, dispatch])

  if (auth0Provider.isAuthenticated && !isSocketIOInit) {
    return <ZFullScreenLoading />
  }

  return (
    // Change the order in which material-ui injects its stylesheets - shorturl.at/wAWZ8
    <StylesProvider injectFirst>
      <Router history={history}>
        {/* Define a react context for theme that is available to any child component - shorturl.at/orzAW */}
        <ThemeIdContext.Provider value={currentThemeId}>
          {/* Define a react context for theme that is used by mui components - shorturl.at/ct157 */}
          <MuiThemeProvider theme={theme}>
            <div className="App" style={{ height: "inherit" }}>
              <MainAppBar>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} />} />
                    <TitledRoute path="/home" render={(props) => <Home {...props} />} />
                    <PrivateRoute
                      exact
                      path="/stores/:id"
                      socket={socket.current}
                      component={Store}
                      title="Store"
                    />
                    <PrivateRoute
                      exact
                      path="/stores/:storeId/issue-form"
                      component={IssueForm}
                      title="Issue Form"
                    />
                    <PrivateRoute exact path="/stores_list" component={Stores} title="Stores" />
                    <PrivateRoute
                      exact
                      path="/shelf_inventory/:id"
                      socket={socket.current}
                      component={ShelfInventory}
                      title="Shelf Inventory"
                    />
                    <PrivateRoute
                      exact
                      path="/lane_calibration/:id"
                      socket={socket.current}
                      component={LaneCalibration}
                      title="Lane Calibration"
                    />
                    <PrivateRoute
                      exact
                      path="/carts_dashboard/:id"
                      socket={socket.current}
                      component={CartsDashboard}
                      title="Carts"
                    />
                    <PrivateRoute
                      exact
                      path="/events_dashboard/:id"
                      component={EventsDashboard}
                      title="Events"
                    />
                    <PrivateRoute
                      exact
                      path="/annotator/:id"
                      component={Annotator}
                      title="New Annotator"
                    />
                    <PrivateRoute
                      exact
                      path="/refunds/:id/:cart_id?"
                      component={RefundDashboard}
                      title="Refund Dashboard"
                    />
                    <PrivateRoute
                      exact
                      path="/orders/:storeId"
                      socket={socket.current}
                      component={Orders}
                      title="Orders"
                    />
                    <PrivateRoute
                      exact
                      path="/orders/:storeId/:id"
                      component={CartDetail}
                      title="Orders"
                    />
                    <PrivateRoute
                      exact
                      path="/customers/:id"
                      component={Customers}
                      title="Customers"
                    />
                    <PrivateRoute
                      exact
                      path="/operator_dashboard"
                      component={OperatorDashboard}
                      title="Operator Dashboard"
                    />
                    <PrivateRoute
                      exact
                      path="/skus/:id"
                      socket={socket.current}
                      component={Skus}
                      title="SKU Catalog"
                    />
                    <PrivateRoute
                      exact
                      path="/device_control/:id"
                      component={DeviceControlPage}
                      title="Device Control"
                    />
                    <PrivateRoute
                      exact
                      path="/multiple-camera-view"
                      component={StoreMultipleCameraView}
                      title="Multiple Camera View"
                    />
                    <PrivateRoute
                      exact
                      path="/relative-video-view"
                      component={RelativeVideoViewDashboard}
                      title="Relative Video View"
                    />
                    <PrivateRoute
                      exact
                      path="/reports/:storeId"
                      component={Reports}
                      title="Reports - Daily Transactions"
                    />
                    <PrivateRoute
                      exact
                      path="/new-reports/:storeId"
                      component={ReportDashboardV2}
                      title="Reports Dashboard"
                    />
                    <PrivateRoute
                      path={STORE_CONFIG_BASE_PATH}
                      component={StoreConfigApp}
                      title="Store Config"
                    />
                    <PrivateRoute
                      exact
                      path="/camera_graph/:storeId"
                      component={CamerasGraphManagement}
                      title="Camera Graph Management"
                    />
                    <PrivateRoute
                      exact
                      path="/shelves_config/:storeId"
                      component={ShelvesConfig}
                      title="Shelves Configuration"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/active-users"
                      component={ActiveUsers}
                      title="HITL Dashboard - Active Users"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/paused-stores"
                      component={PausedStores}
                      title="HITL Dashboard - Pause Stores"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/pending-carts"
                      component={PendingCarts}
                      title="HITL Dashboard - Pending Carts"
                    />
                    <Route
                      exact
                      path="/hitl_dashboard/hitl-rules"
                      render={() => <Redirect to="/hitl_dashboard/assignment-rules" replace />}
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/assignment-rules"
                      component={AssignmentRules}
                      title="HITL Dashboard - Rules"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/auto-solve-hitl-tasks-rules"
                      component={AutoSolveHITLTasksRules}
                      title="HITL Dashboard - Auto Solve HITL Tasks Rules"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/expert-operators"
                      component={ExpertOperators}
                      title="HITL Dashboard - Expert Operators"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/error-queue"
                      component={ErrorQueue}
                      title="HITL Dashboard - Error Queue"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/storepriority"
                      component={StorePriority}
                      title="HITL Dashboard - Store Priority"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/pending-tasks"
                      component={PendingTasks}
                      title="HITL Dashboard - Pending Tasks"
                    />
                    <PrivateRoute
                      exact
                      path="/hitl_dashboard/admin-panel"
                      component={AdminPanel}
                      title="HITL Dashboard - Admin Panel"
                    />
                    <PrivateRoute
                      exact
                      path="/access_management/:storeId"
                      component={UserManagement}
                      title="Access Management"
                    />
                    <PrivateRoute
                      exact
                      path="/configurations/payment/:storeId"
                      component={ConfigurationsPage}
                      title="Configurations"
                    />
                    <PrivateRoute
                      exact
                      path="/configurations/feature_flags/:storeId"
                      component={ConfigurationsPage}
                      title="Configurations"
                    />
                    <PrivateRoute
                      exact
                      path="/configurations/receipt_qr/:storeId"
                      component={ConfigurationsPage}
                      title="Configurations"
                    />

                    <PrivateRoute
                      exact
                      path="/support/:storeId"
                      component={Support}
                      title="Crew App Support Push Notification"
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard-logout"
                      component={Logout}
                      title="Logout"
                    />
                    <Route
                      render={() => (
                        <div>
                          <p>404 page not found</p>
                          <a href="/home">home</a>
                        </div>
                      )}
                    />
                  </Switch>
                </Suspense>
              </MainAppBar>
            </div>
          </MuiThemeProvider>
        </ThemeIdContext.Provider>
      </Router>
    </StylesProvider>
  )
}

export default withAuth0(App)
export { ThemeIdContext, currentThemeId }
