import { updateShelves } from "./shelves"
import { getStore, getStoreMaxLaneNoiseThreshold, getStoreLayout, upsertStoreLayout } from "./store"

export const api = {
  // Store APIs
  getStore,
  getStoreMaxLaneNoiseThreshold,
  getStoreLayout,
  upsertStoreLayout,

  // Shelf APis
  updateShelves,
}
