import { combineReducers } from "redux"

import { SAVE_IMPORT_SKU_RESULTS, REMOVE_IMPORT_SKU_RESULTS } from "../actions"
import { user } from "./user"

const title = (state = "", action) => {
  switch (action.type) {
    case "CHANGE_TITLE":
      return action.title
    default:
      return state
  }
}

const stores = (state = { userStores: [], hasFetched: false }, action) => {
  switch (action.type) {
    case "SET_USER_STORES":
      return {
        ...state,
        userStores: action.stores,
      }
    case "HAS_FETCHED_USER_STORES":
      return {
        ...state,
        hasFetched: action.hasFetched,
      }
    default:
      return state
  }
}

const skus = (state = { categories: [], hasFetched: false }, action) => {
  switch (action.type) {
    case "SET_TENANT_SKU_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
      }
    case "SET_SKU_CATEGORIES_TENANT_ID":
      return {
        ...state,
        tenantId: action.tenantId,
      }
    case "HAS_FETCHED_TENANT_SKU_CATEGORIES":
      return {
        ...state,
        hasFetched: action.hasFetched,
      }
    default:
      return state
  }
}

const auth = (state = { isUnauthorized: false }, action) => {
  switch (action.type) {
    case "SET_IS_UNAUTHORIZED":
      return {
        ...state,
        isUnauthorized: action.isUnauthorized,
        errorCode: action.errorCode,
        errorDescription: action.errorDescription,
      }
    default:
      return state
  }
}

const receipt = (state = null, action) => {
  switch (action.type) {
    case "SET_RECEIPT":
      return action.receipt
    default:
      return state
  }
}

function importProgressResults(state = {}, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SAVE_IMPORT_SKU_RESULTS:
      let currentImportProgressResult = Array.isArray(state.importProgressResults)
        ? state.importProgressResults.filter(
            (obj) => obj.requestKey === action.importProgressResults.requestKey
          )
        : null
      let res
      if (currentImportProgressResult && currentImportProgressResult.length > 0) {
        res = state.importProgressResults.map((content) => {
          return content.requestKey === action.importProgressResults.requestKey
            ? {
                ...content,
                processedDataCount: action.importProgressResults.processedDataCount,
                progressPercentage: action.importProgressResults.progressPercentage,
                remainingSkusProcessingTime:
                  action.importProgressResults.remainingSkusProcessingTime,
                startTime: action.importProgressResults.startTime,
                total: action.importProgressResults.total,
                requestKey: action.importProgressResults.requestKey,
                email: action.importProgressResults.email,
                failed: action.importProgressResults.failed,
                succeeded: action.importProgressResults.succeeded,
                status: action.importProgressResults.status,
              }
            : content
        })
      } else {
        res = Array.isArray(state.importProgressResults)
          ? state.importProgressResults.concat([action.importProgressResults])
          : [action.importProgressResults]
      }
      newState.importProgressResults = res
      return newState
    case REMOVE_IMPORT_SKU_RESULTS:
      const newImportProgressResults = Array.isArray(state.importProgressResults)
        ? state.importProgressResults.filter(function (obj) {
            return obj.requestKey !== action.requestKey
          })
        : {}
      newState.importProgressResults = newImportProgressResults
      return newState
    default:
      return state
  }
}

export default combineReducers({
  title,
  stores,
  skus,
  auth,
  receipt,
  importProgressResults,
  user,
})
