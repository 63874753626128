import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import { setAuth0Provider } from "../../utils/api"
import App from "../../App"
import ZFullScreenLoading from "../presentational/ZFullScreenLoading"

/**
 * Loads the React app once the Auth service is done loading
 */
function AuthWall() {
  const auth0Provider = useAuth0()
  const [isAuth0ProviderInit, setIsAuth0ProviderInit] = useState(false)

  useEffect(() => {
    if (!auth0Provider.isLoading) {
      // Set auth0 provider for apis. Setting once avoids
      //  having to pass it from each component every time an api call is made.
      setAuth0Provider(auth0Provider)
      setIsAuth0ProviderInit(true)
    }
  }, [auth0Provider])

  if (!isAuth0ProviderInit) {
    return <ZFullScreenLoading />
  }
  if (auth0Provider.error) {
    console.error("auth0Provider error", auth0Provider)
  }
  return <App />
}

export default AuthWall
