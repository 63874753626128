import { StoreLayoutType } from "store-config-interfaces"

import * as env from "../utils/env"
import * as apiBase from "../utils/api"
import { wrappedFetch } from "./apiHelpers"

const zippinApiBaseUrl = env.getZippinPlatformAPI()
const operatorBackendBaseUrl = env.getOperatorBackendAPI()

/**
 * Gets the store's details
 * @param {string} storeId The id of the store to retrieve information about
 * Returns null if the request was not successful
 */
const getStore = async (storeId: string) => {
  const response = await wrappedFetch(`${zippinApiBaseUrl}/stores/${storeId}`, {
    method: "GET",
  })

  if (response.status !== 200) return null
  return response.json()
}

/**
 * Gets the store's maximum lane noise threshold value, in grams
 * @param {string} storeId The id of the store to retrieve information about
 * Returns null if the request was not successful
 */
const getStoreMaxLaneNoiseThreshold = async (storeId: string) => {
  const response = await wrappedFetch(
    `${operatorBackendBaseUrl}/stores/${storeId}/max-lane-threshold`,
    {
      method: "GET",
    }
  )
  const responseJson = await response.json()

  if (response.status !== 200) return null
  return responseJson.maxLaneNoiseThresholdGrams
}

/**
 * Fetch the layout image for a store given the layout type requested.
 */
const getStoreLayout = async (storeId: string, layoutType: StoreLayoutType) => {
  const storeConfigAPI = env.getStoreConfigAPI()
  const response = await apiBase.wrappedFetch(
    `${storeConfigAPI}/stores/${storeId}/layout/${layoutType}`,
    {
      method: "GET",
    }
  )
  if (response.status !== 200) {
    throw new Error(`Error fetching the layout=${layoutType} for store=${storeId}`)
  }
  return response.json()
}

/**
 * Upsert the location of the layout file for a store for the given layout type.
 */
const upsertStoreLayout = async (
  storeId: string,
  layoutType: StoreLayoutType,
  filepath: string
) => {
  const storeConfigAPI = env.getStoreConfigAPI()
  const response = await apiBase.wrappedFetch(
    `${storeConfigAPI}/stores/${storeId}/layout/${layoutType}`,
    {
      method: "POST",
      body: JSON.stringify({ filepath }),
    }
  )
  if (response.status !== 200) {
    throw new Error(
      `Error updating the layout=${layoutType} for store=${storeId}: ${JSON.stringify(response)}`
    )
  }
  return response.json()
}

export { getStore, getStoreLayout, getStoreMaxLaneNoiseThreshold, upsertStoreLayout }
