import * as overrideConsole from "./utils/overrideConsole"
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "./store/reducers"
import thunk from "redux-thunk"
import logger from "redux-logger"
import * as api from "./utils/api"
import * as env from "./utils/env"
import { Auth0Provider } from "@auth0/auth0-react"
import AuthWall from "./components/Auth/AuthWall"
import history from "./history"

console.log("console override ", overrideConsole.isConsoleOverridden())
const store = createStore(rootReducer, applyMiddleware(logger, thunk.withExtraArgument({ api })))

/**
 * Called by auth0 to return the user to a protect route,
 *  after the user logs in.
 *  See https://auth0.github.io/auth0-react/globals.html#protect-a-route
 * @param {object} appState https://auth0.github.io/auth0-react/globals.html#appstate
 */
const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname)
}

ReactDOM.render(
  <Auth0Provider
    domain={"auth.getzippin.com"}
    clientId={env.getAuth0ClientId()}
    redirectUri={window.location.origin}
    scope="openid profile email"
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <AuthWall></AuthWall>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
