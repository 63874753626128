import * as env from "../utils/env"
import { wrappedFetch } from "./apiHelpers"

const zippinApiBaseUrl = env.getZippinPlatformAPI()

/**
 * Call zippin-api API to update a shelf
 * @param {string} tenantStoreId The tenant id for the desired store
 * @param {string} shelfIds The ids of the shelves in the store
 * @param {string | undefined} sku The sku value, will set default SKU if quantity isn't provided
 * @param {number | undefined} quantity The new quantity value for the provided SKU
 * @param {number | undefined} capacity The new capacity value for the shelf
 */
export const updateShelves = async (
  tenantStoreId: string,
  shelfIds: Array<string>,
  sku: string | undefined,
  quantity: number | undefined,
  capacity: number | undefined
) => {
  if (!tenantStoreId) {
    throw new Error("Missing Required Field: Tenant store identifier is required")
  } else if (!(shelfIds && Array.isArray(shelfIds) && shelfIds.length > 0)) {
    throw new Error("Missing Required Field: List of shelf Ids is required")
  } else if (!sku && quantity) {
    throw new Error("Missing Required Field: SKU is required when setting quantity")
  }
  const shelves = shelfIds.map((shelfId) => ({ shelfId, sku, quantity, capacity }))

  const response = await wrappedFetch(`${zippinApiBaseUrl}/shelves/`, {
    method: "PUT",
    body: JSON.stringify([
      {
        storeId: tenantStoreId,
        shelves,
      },
    ]),
  })

  if (!response.ok) {
    const responseBody = await response.json()
    const errorMessage = responseBody.data ? responseBody.data.message : responseBody.message

    throw new Error(errorMessage)
  }

  return response.json()
}
