const user = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_USER_EMAIL": {
      return {
        ...state,
        email: action.email,
      }
    }
    case "SET_USER_STORE_ACCESS": {
      return {
        ...state,
        userStoreAccess: action.userStoreAccess,
      }
    }
    default: {
      return state
    }
  }
}
export { user }
