// import { interfaces } from "@zippin-js/core"
import { getUserEmail, getUserStoreAccess } from "../../utils/api"
import { formatUserStoreAccess } from "../../utils/user"

const actionFetchUserStoreAccess = () => {
  return async (dispatch: CallableFunction) => {
    try {
      const email = getUserEmail()
      const userStoreAccessResponse = await getUserStoreAccess()
      const userStoreAccess = formatUserStoreAccess(userStoreAccessResponse.data)
      dispatch({ type: "SET_USER_STORE_ACCESS", userStoreAccess })
      dispatch({ type: "SET_USER_EMAIL", email })
    } catch (e) {
      console.log("Error actionFetchUserStoreAccess", e)
    }
  }
}

export { actionFetchUserStoreAccess }
