import React from "react"
import { logoGif } from "../../static/images"

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
}

function Loading() {
  return (
    <div style={style}>
      <img id="loading-zippin-logo" src={logoGif} alt="Logo" />
    </div>
  )
}

export default Loading
