/**
 * File that contains icons for the menu items for stores.
 */
import React from "react"
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest"
import DvrIcon from "@material-ui/icons/Dvr"
import KeyboardIcon from "@material-ui/icons/Keyboard"
import ViewListIcon from "@material-ui/icons/ViewList"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import FastFoodIcon from "@material-ui/icons/Fastfood"
import KitchenIcon from "@material-ui/icons/Kitchen"
import EventIcon from "@material-ui/icons/Event"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import PeopleIcon from "@material-ui/icons/People"
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import MovieCreationIcon from "@material-ui/icons/MovieCreation"
import TuneIcon from "@material-ui/icons/Tune"
import { FitnessCenter } from "@material-ui/icons"

export const MonetizationIcon = (props) => {
  return <MonetizationOnIcon />
}

export const OperatorTasksIcon = (props) => {
  return <DvrIcon />
}

export const OperatorVerifyIcon = (props) => {
  return <KeyboardIcon />
}

export const OperatorDashBoardIcon = (props) => {
  return <ViewListIcon />
}

export const ShelvesConfigIcon = (props) => {
  return <SettingsSuggestIcon />
}

export const CartsDashIcon = (props) => {
  return <ShoppingCartIcon />
}

export const SkuCatalogIcon = (props) => {
  return <FastFoodIcon />
}

export const ShelfInventoryIcon = (props) => {
  return <KitchenIcon />
}

export const LaneCalibrationIcon = (props) => {
  return <FitnessCenter />
}

export const EventsDashIcon = (props) => {
  return <EventIcon />
}

export const ShelfAnnotatorIcon = (props) => {
  return <FullscreenIcon />
}

export const CustomersIcon = (props) => {
  return <PeopleIcon />
}

export const OrdersDashIcon = (props) => {
  return <ShoppingBasketIcon />
}

export const CartRefundIcon = (props) => {
  return <AssignmentReturnIcon />
}

export const DataCollectionIcon = (props) => {
  return <MovieCreationIcon />
}

export const DeviceControlIcon = (props) => {
  return <SettingsApplicationsIcon />
}

export const StoreConfigIcon = (pros) => {
  return <TuneIcon />
}
