import { reportError } from "./api"
import { isLocalEnv } from "./env"

/**
 * Some retry logic may retry functions on error. This limits the number
 * of errors reported.
 */
const LIMIT_ERRORS_REPORTED = 10
let numOfErrorsReported = 0

const oldConsoleError = window.console.error
const newConsoleError = (err) => {
  if (numOfErrorsReported < LIMIT_ERRORS_REPORTED) {
    numOfErrorsReported++
    //https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
    reportError(JSON.stringify(err, Object.getOwnPropertyNames(err)))
  }
  oldConsoleError(err)
}
const console = {
  ...window.console,
  error: isLocalEnv ? oldConsoleError : newConsoleError,
}
window.console = console

/**
 * We want to perform this override before loading anything else
 * This simple method will be imported at the top of index.js
 */
export const isConsoleOverridden = () => {
  return true
}
