import React, { Component } from "react"

import { Route } from "react-router-dom"
import { connect } from "react-redux"

/**
 * A wrapped react-router-dom Route, which should be used
 * for each route. It sets a title of the page in the main
 * navigation bar of the dashboard. Passes props
 * to the component rendered when the route is accessed.
 */
class TitledRoute extends Component {
  componentDidMount() {
    const { title } = this.props
    if (title) this.props.changeTitle(title)
    this.setDocumentTitle()
  }

  componentDidUpdate(prevProps) {
    const { title } = this.props
    if (title !== prevProps.title) {
      if (title) this.props.changeTitle(title)
    }
    this.setDocumentTitle()
  }

  /**
   * Set the title of the document.
   */
  setDocumentTitle = () => {
    const params = this.props?.computedMatch?.params
    // The store ID is either a part of the params as `:id` or `:storeId`
    const storeId = params ? params.id ?? params.storeId : undefined
    const documentTitleUsingProps = storeId ? `${storeId}: ${this.props.title}` : this.props.title
    // If no title from props; have a backup title.
    document.title = documentTitleUsingProps ?? "Zippin Dashboard"
  }

  render() {
    const { ...rest } = this.props

    return <Route {...rest} render={this.props.render} />
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeTitle: (title) => dispatch({ type: "CHANGE_TITLE", title: title }),
})

export default connect(null, mapDispatchToProps)(TitledRoute)
