import * as env from "../utils/env"

// Globals
let auth0Provider: any = undefined

/**
 * Sets the auth0Provider object
 * @param {any} newAuth0Provider The "Auth0ContextInterface" object returned from @auth0/auth0-react's useAuth0()
 */
const setAuth0Provider = (newAuth0Provider: any) => {
  auth0Provider = newAuth0Provider
}

/**
 *
 */
const getBearerToken = async () => {
  if (!auth0Provider) {
    throw new Error(
      "Error api/apiHelpers.getBearerToken: auth0Provider must be set before calling a Zippin api"
    )
  }

  const audience = env.getAuth0Audience()
  return await auth0Provider.getAccessTokenSilently({ audience })
}

/**
 * Sets common headers such as content-type and authorization for zippin-api calls
 */
const getHeaders = async () => {
  try {
    const accessToken = await getBearerToken()
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Bearer ${accessToken}`)
    return headers
  } catch (error) {
    console.error("api/apiHelpers.getHeaders:", error)
    throw error
  }
}

/**
 * Sets common fetch params such as headers and calls fetch
 * @param {string} url The url destination for fetch
 * @param {object} params Additional paramters for fetch (Not including headers)
 * @returns fetch result
 */
const wrappedFetch = async (url: string, params: object) => {
  try {
    const headers = await getHeaders()
    const result = await fetch(url, {
      ...params,
      headers,
    })
    return result
  } catch (error) {
    if (!(error instanceof Error)) {
      throw new Error("api/apiHelpers.wrappedFetch Error: Unknown error")
    }

    console.error("api/apiHelpers.wrappedFetch Error:", url, params, error)
    throw error
  }
}

export { auth0Provider, setAuth0Provider, getBearerToken, getHeaders, wrappedFetch }
